import { NotFoundCard } from '~/components/ErrorBoundary/NotFoundCard'
import { DefaultLayout } from '~/templates/layouts/DefaultLayout'

// https://nextjs.org/docs/advanced-features/custom-error-page
const Custom404 = () => {
  return <NotFoundCard />
}

Custom404.getLayout = DefaultLayout

export default Custom404
